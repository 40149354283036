import logoVideo from 'src/Assests/icons/logo.mp4';

function Loader({ isLoading }) {

    return (
        <div class="loader-container" style={{ display: isLoading ? 'flex' : 'none' }} >
            <video src={logoVideo} width="600" height="auto" autoPlay muted loop />
        </div>

    )
}

export default Loader