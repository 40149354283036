import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import centerOne from "../../Assests/icons/centerIconOne.svg";
import centerThre from "../../Assests/icons/centerIconThree.svg";
import centerTwo from "../../Assests/icons/centerIcontwo.svg";
import imageLeft from "../../Assests/icons/sideRound.svg";
import imageRight from "../../Assests/icons/sideright.svg";

const testimonialsData = [
  {
    name: "R wilson",
    review:
      "If you take an honest advice from me, the work and quality is amazing. I had my website revamped from these guys and it went awesome.",
  },
  {
    name: "Oriyana",
    review:
      "I have to leave a review for Softlogicapp. The service I received was over the top notch quality. I explained my mission and vision and they worked and worked through all of my revisions until I was happy. Thank you for your time as a patient with all the support.",
  },
  {
    name: "Robert",
    review:
      "I am so happy with my website, the customer service, and more importantly the communication. All of my concerns were addressed in ample time and I was able to use my logo right away and got my website in less than two weeks with a one time cost.",
  },
  {
    name: "Steve Jose",
    review:
      "Excellent experience. Initially Got 2 rounds of many design possibilities, which helped narrow down on a decent design. Later I received more modifications in response to my requests and finally I had the ideal logo that both my business partner and I really loved!",
  },
  {
    name: "Mark Robinson",
    review:
      "Working with Soft Logic App on developing our new modern website for our business has been a fantastic experience Our project manager has been exceptional assisting in resolving technical issues and guiding me through the process of maximizing the potential of our new company website.",
  },
  {
    name: "Arthur",
    review:
      "your web design services exceeded my expectations! The sleek interface, user-friendly navigation, and creative elements truly elevate our online presence. Your team's dedication and attention to detail are commendable. Highly recommend your services!",
  },
  {
    name: "John Miller",
    review:
      "I'm thrilled with the exceptional web design delivered by your team. The seamless blend of aesthetics and functionality creates a user-centric experience that exceeds expectations. The website's visual appeal and effortless navigation truly set it apart. Thank you for your outstanding work!",
  },
  {
    name: "Hannah David",
    review:
      "I needed a website for my small bookstore. Initially, I was hesitant because I didn’t know whether my website would catch the audience’s attention. However, I was really happy with the end product. Not only did they manage to make my website rank in my region, but it also helped me gain new customers and increase my online reach. I would highly recommend trying this company; they are professional people.",
  },
  {
    name: "Timothy",
    review:
      "I am really happy with the quality of my website; it was delivered on time and as expected. The communication was excellent, and I got my design revised twice without any hurdles.",
  },
];

const Testimonial = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === testimonialsData.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? testimonialsData.length - 1 : prevSlide - 1
    );
  };

  useEffect(() => {
    const slides = document.querySelectorAll(".slide");
    const maxSlide = slides.length;

    const goToSlide = (slide) => {
      slides.forEach(
        (s, i) => (s.style.transform = `translateX(${100 * (i - slide)}%)`)
      );
    };

    const interval = setInterval(nextSlide, 5000); // Auto slide every 5 seconds

    goToSlide(currentSlide);

    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft") prevSlide();
      if (e.key === "ArrowRight") nextSlide();
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      clearInterval(interval);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentSlide]);

  return (
    <div className="main-container-testimonial pt-32 ">
      <Helmet>
        <title>Client Testimonials | SoftlogicApp</title>
        <meta
          name="description"
          content="Read glowing feedback from our valued clients. See customer testimonials and positive reviews about our services."
        />
        <meta
          name="keywords"
          content="client testimonials, customer feedback, positive reviews, Softlogicapp reviews"
        />
      </Helmet>
      <div className="p-absolute w-100 d-flex justify-content-center my-45">
        <p className="invesment-text p-0">“Testimonials”</p>
        <p className="ring-now fw-bold">
          {" "}
          Glowing Feedback from our Valued Clients
        </p>
      </div>
      <p className="secondary-text">
        Our esteemed clients from last year are thrilled with our multiple IT
        services. Their <br /> feedback speaks volumes about our excellence.
      </p>

      <div className="testimonials-div">
        <div className="testimonial-cards">
          <img src={imageLeft} alt="" className="imgae-left" loading='lazy' />
          <img src={imageRight} alt="" className="imgae-right" loading='lazy' />
        </div>
        <div className="testimonials-text">
          <section class="section" id="section--3">
            <div class="slider">
              {testimonialsData.map((testimonial, index) => (
                <div
                  key={index}
                  className={`slide slide--${index + 1} ${index === currentSlide ? "active" : ""
                    }`}
                >
                  <div className="testimonial">
                    <h5 className="testimonial__header">{testimonial.name}</h5>
                    <blockquote className="testimonial__text">
                      {testimonial.review}
                    </blockquote>
                  </div>
                </div>
              ))}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="29"
                fill="none"
                viewBox="0 0 17 29"
                className="slider__btn slider__btn--left"
                onClick={prevSlide}
              >
                <path
                  fill="#fff"
                  d="M12.795 28.279L.705 16.167a2.167 2.167 0 010-3.055L12.795 1A2.167 2.167 0 0116.5 2.54V26.74a2.166 2.166 0 01-3.705 1.539z"
                ></path>
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="29"
                fill="none"
                viewBox="0 0 17 29"
                className="slider__btn slider__btn--right"
                onClick={nextSlide}
              >
                <path
                  fill="#fff"
                  d="M4.205 28.279l12.09-12.112a2.167 2.167 0 000-3.055L4.205 1A2.167 2.167 0 00.5 2.54V26.74a2.166 2.166 0 003.705 1.539z"
                ></path>
              </svg>

              <p className="text-center text-div">
                Highly Recommended and Trusted By
              </p>
            </div>
            <div className="imageDiv-center">
              <div>
                <img src={centerOne} alt="" className="brandlogos" loading='lazy' />
              </div>
              <div className="margin-10">
                <img src={centerTwo} alt="" className="brandlogos" loading='lazy' />
              </div>
              <div>
                <img src={centerThre} alt="" className="brandlogos" loading='lazy' />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
