import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useInView } from 'react-intersection-observer';
import Design from 'src/assets/images/project-design.png';
import useVisible from 'src/hooks/useVisible';
import ProjectData from '../../data/projectsData.json';
import TiltCard from "../base/TiltCard";
import { Modal } from "../base/modal";
import { ModalSmall } from "../base/modalSmall";


const billed = [
      { id: 1, label: "Web Development" },
      { id: 2, label: "App Development" },
      { id: 3, label: "E-Commerce" },
      { id: 4, label: "Logo Design" },
      { id: 5, label: "Social Media Marketing" },
      { id: 6, label: "Branding" }
];


function Projects() {
      const [isLoading, setIsLoading] = useState(false)
      const [activeFilter, setActiveFilter] = useState("Web Development");
      const [visibleItems, setVisibleItems] = useState([]);
      const { ref, controls } = useVisible()
      const { ref: ref2, controls: controls2 } = useVisible()
      const controlsRow = useAnimation();

      const [selectedImage, setSelectedImage] = useState({
      });
      const [refRow, inView] = useInView({
            triggerOnce: true,
            rootMargin: '-100px 0px',
      });

      const cardVariants = {
            visible: { opacity: 1, translateY: 0, transition: { duration: 0.5 } },
            hidden: { opacity: 0, translateY: 50 }
      };

      const openModal = (project) => {

            setSelectedImage(project);
      };
      const closeModal = () => {
            setSelectedImage({
            });
      };


      const handleChange = (label) => {
            setIsLoading(true)
            setActiveFilter(label);
            setTimeout(() => {
                  setIsLoading(false)
            }, 1500);
      };



      const hoverVariants = {
            initial: {
                  y: 0,
                  transition: {
                        duration: 2,
                        ease: "linear"
                  }
            },
            hover: {
                  // y: '-50%',
                  y: '-55%',
                  transition: {
                        duration: 2,
                        ease: "linear"
                  }
            }
      };

      const tiltVariants = {
            initial: {
                  scale: 1,
                  rotateX: 0,
                  rotateY: 0,
                  transition: {
                        type: "spring",
                        stiffness: 100,
                  }
            },
            hover: {
                  scale: 1.05,
                  rotateX: 5,
                  rotateY: 5,
                  transition: {
                        type: "spring",
                        stiffness: 100,
                  }
            }
      };

      useEffect(() => {
            const filteredItems = ProjectData.filter(item => item.category.includes(activeFilter));
            filteredItems?.map(elem => console.log(elem))
            setVisibleItems(filteredItems);
      }, [activeFilter]);

      useEffect(() => {
            if (inView) {
                  controlsRow.start("visible");
            }
      }, [controlsRow, inView])

      return (

            <div className="projects container" id="projects">
                  <Helmet>
                        <title>Our Portfolio | SoftlogicApp</title>
                        <meta
                              name="description"
                              content="View our portfolio of successful app development and web design projects. Check out our case studies and client success stories."
                        />
                        <meta
                              name="keywords"
                              content="app development portfolio, web design portfolio, successful projects, case studies, SoftlogicApp portfolio"
                        />
                  </Helmet>
                  <img className='design' src={Design} alt={`${Design}dsa`} />
                  <div className="text-row">

                        <motion.div
                              variants={{
                                    hidden: { opacity: 0, x: -75 },
                                    visible: { opacity: 1, x: 0 },
                              }}
                              initial={'hidden'}
                              animate={controls}
                              transition={{ duration: 0.5, delay: 0.5 }}
                              className='heading'
                        >

                              <div ref={ref}>
                                    <p >Experince <br /> speaks in growing your</p>
                                    <h1>Business</h1>
                              </div>
                        </motion.div>


                        <motion.div
                              id='portfolio'
                              variants={{
                                    hidden: { opacity: 0, x: -75 },
                                    visible: { opacity: 1, x: 0 },
                              }}
                              initial={'hidden'}
                              animate={controls}
                              transition={{ duration: 0.5, delay: 0.5 }}
                              className='desc'
                        >

                              <p ref={ref}>
                                    In the competitive online world, a standout website is your gateway to success. Our USA-based web development company specializes in crafting tailored solutions for businesses of all sizes.
                              </p>
                        </motion.div>

                  </div>
                  <div className="heading-portfolio">
                        <p className="heading-port">Portfolio</p>
                  </div>
                  <motion.div
                        variants={{
                              hidden: { opacity: 0, y: -75 },
                              visible: { opacity: 1, y: 0 },
                        }}
                        initial={'hidden'}
                        animate={controls}
                        transition={{ duration: 0.5, delay: 0.5 }}
                        className="tabs"
                  >
                        {billed.map((data) => (

                              <div className={activeFilter?.toLowerCase() === data?.label?.toLowerCase() ? "tab active" : "tab"} onClick={() => handleChange(data.label)}>
                                    {data?.label}
                              </div>

                        ))}
                  </motion.div>

                  <motion.div
                        // variants={{
                        //       hidden: { opacity: 0, y: 75 },
                        //       visible: { opacity: 1, y: 0 },
                        // }}
                        variants={cardVariants}
                        ref={refRow}
                        initial={'hidden'}
                        animate={controlsRow}
                        transition={{ duration: 0.5, delay: 0.5 }}
                        className="cards"
                  > {isLoading ?
                        <div id='loader-div'>
                              <span class="loader"></span>
                        </div>
                        :

                        visibleItems?.map((elem) =>
                        (
                              elem?.category[0] === "Web Development" || elem?.category[0] === "E-Commerce" || elem?.category[0] === "Social Media Marketing" ?
                                    <div className="card">
                                          {/* <img className='attach' src={Attach} alt="" />
                                        <img className='border' src={Border} alt="" /> */}
                                          {/* <img className='main-img' src={elem.image} alt="" /> */}
                                          <motion.img
                                                className='main-img'
                                                src={elem?.image}
                                                onClick={() => openModal(elem)}
                                                alt=""
                                                initial="initial"
                                                animate="initial"
                                                variants={hoverVariants}
                                                whileHover="hover"
                                                style={{ originX: 0.5, originY: 0.5 }}
                                          />
                                    </div>
                                    : <TiltCard img={elem?.image} onClick={() => openModal(elem)} />
                        ))}
                        <ModalSmall isOpen={!!selectedImage?.image && selectedImage.category[0] == "Logo Design"} onClose={closeModal} data={selectedImage} />
                        <Modal isOpen={!!selectedImage?.image && selectedImage.category[0] == "App Development"} onClose={closeModal} data={selectedImage} />
                  </motion.div>
                  <div className="divider"></div>
            </div>
      )
}

export default Projects

