import mainLogo from "../../Assests/images/Symb+Text.svg";

import { useEffect, useRef, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import domainIcon from "../../Assests/icons/domain.svg";
import phoneIcon from "../../Assests/icons/fi-ss-phone-call.svg";
import hambugerIcon from "../../Assests/icons/hamburger-s.svg";
import logoDesingIcon from "../../Assests/icons/logoDesign.svg";
import mobileAppIcon from "../../Assests/icons/mobileApp.svg";
import seoIcon from "../../Assests/icons/seo.svg";
import webppIcon from "../../Assests/icons/webAppDev.svg";
import webDesignIcon from "../../Assests/icons/webDev.svg";

const Header = ({ setActiveName }) => {
  const [open, setOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [activeClass, setActiveClass] = useState('')
  const [isBodyOverflowHidden, setIsBodyOverflowHidden] = useState(false)
  const dropdownRef = useRef(null);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  const closeDropdown = () => {
    setOpen(false);
  };
  const scrollToDiv = (divId) => {
    setActiveClass(divId)
    closeDropdown()
    var element = document.getElementById(divId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };



  const handleOutsideClick = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.closest(".navMenu li")
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (showNavbar) {
      // Disable body scrolling when the box is open
      document.body.style.overflow = 'hidden';
      setIsBodyOverflowHidden(true);
    } else {
      // Enable body scrolling when the box is closed
      document.body.style.overflow = 'auto';
      setIsBodyOverflowHidden(false);
    }

    return () => {
      // Cleanup: enable body scrolling on component unmount
      document.body.style.overflow = 'auto';
    };
  }, [showNavbar]);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div

      className={`header d-flex align-items-center flex-row justify-content-evenly py-16 px-10 f-family-poppin ${isSticky ? "sticky" : ""
        }`}
    >
      <div className="mr-5 head-logo" onClick={() => { scrollToDiv("hero-section"); setShowNavbar(false); setActiveName("") }}>
        <Link to="/"> <img src={mainLogo} alt="React Logo" className="cursor-pointer head-logo-img" /></Link>

      </div>
      <div className={`${showNavbar ? "mobileMenu" : "navMenu"}  transtionAnima `} ref={dropdownRef}>
        <div >
          <ul>
            {/* {showNavbar && ( */}
            <li>
              <div
                onClick={handleShowNavbar}
                className={`text-right fs-24 cursor-pointer pr-24 mr-16 showX`}
              >
                <RxCross2 size={24} />
              </div>
            </li>
            {/* )} */}
            <li onClick={() => { scrollToDiv("hero-section"); setShowNavbar(false); setActiveName("") }} className={`${activeClass === 'hero-section' ? "active" : ''}`}>
              {" "}
              <Link to="/">Home</Link>
            </li>
            <li onClick={() => setOpen(!open)} className="cursor-pointer">
              Services
              {open && (
                <div className="dropdownMenu">
                  <ul>
                    <li>
                      <Link to={`/?active=Web Development`} className="secondary-button d-flex align-items-center flex-row justify-content-between btn-Link" onClick={() => { scrollToDiv("price"); setShowNavbar(false); setActiveName("Web Development"); }}>
                        <img
                          src={webppIcon}
                          alt="phoneIcon"
                          className="menuIco pr-10"
                        />
                        Web App Development
                      </Link>
                    </li>
                    <li>
                      <Link to={`/?active=Web Development`} className="secondary-button d-flex align-items-center flex-row justify-content-between btn-Link" onClick={() => { scrollToDiv("price"); setShowNavbar(false); setActiveName("App Development"); }}>
                        <img
                          src={mobileAppIcon}
                          alt="phoneIcon"
                          className="menuIco pr-10"
                        />
                        Mobile App
                      </Link>
                    </li>
                    <li>
                      <Link to={`/?active=E-Commerce`} className="secondary-button d-flex align-items-center flex-row justify-content-between btn-Link" onClick={() => { scrollToDiv("price"); setShowNavbar(false); setActiveName("E-Commerce"); }}>
                        <img
                          src={webDesignIcon}
                          alt="phoneIcon"
                          className="menuIco pr-10"
                        />
                        E-Commerce
                      </Link>
                    </li>
                    {/* <li>
                      <Link to={`/?active=Web Development`} className="secondary-button d-flex align-items-center flex-row justify-content-between btn-Link" onClick={() => { scrollToDiv("price"); setShowNavbar(false); setActiveName("Web Development"); }}>
                        <img
                          src={wordpressIcon}
                          alt="phoneIcon"
                          className="menuIco pr-10"
                        />
                        Wordpress Development
                      </Link>
                    </li> */}
                    <li>
                      <Link to={`/?active=Logo Designing`} className="secondary-button d-flex align-items-center flex-row justify-content-between btn-Link" onClick={() => { scrollToDiv("price"); setShowNavbar(false); setActiveName("Logo Designing"); }}>
                        <img
                          src={logoDesingIcon}
                          alt="phoneIcon"
                          className="menuIco pr-10"
                        />
                        Logo Design
                      </Link>
                    </li>
                    {/* <li>
                      <Link to={`/?active=Social Media Marketing`} className="secondary-button d-flex align-items-center flex-row justify-content-between btn-Link" onClick={() => { scrollToDiv("price"); setShowNavbar(false); setActiveName("Social Media Marketing"); }}>
                        <img
                          src={contentIcon}
                          alt="phoneIcon"
                          className="menuIco pr-10"
                        />
                        Content Writing
                      </Link>
                    </li> */}
                    <li>
                      <Link to={`/?active=Web Development`} className="secondary-button d-flex align-items-center flex-row justify-content-between btn-Link" onClick={() => { scrollToDiv("price"); setShowNavbar(false); setActiveName("E-Commerce"); }}>
                        <img
                          src={webDesignIcon}
                          alt="phoneIcon"
                          className="menuIco pr-10"
                        />
                        Web Design
                      </Link>
                    </li>

                    <li>
                      <Link to={`/?active=Domain & Hosting`} className="secondary-button d-flex align-items-center flex-row justify-content-between btn-Link" onClick={() => { scrollToDiv("price"); setShowNavbar(false); setActiveName("Domain & Hosting"); }}>
                        <img
                          src={domainIcon}
                          alt="phoneIcon"
                          className="menuIco pr-10"
                        />
                        Domain & Hosting
                      </Link>
                    </li>
                    <li>
                      <Link to={`/?active=SEO`} className="secondary-button d-flex align-items-center flex-row justify-content-between btn-Link" onClick={() => { scrollToDiv("price"); setShowNavbar(false); setActiveName("SEO"); }}>
                        <img
                          src={seoIcon}
                          alt="phoneIcon"
                          className="menuIco pr-10"
                        />
                        SEO
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </li>
            <li onClick={() => { scrollToDiv("portfolio"); setShowNavbar(false); setActiveName("") }} className={`${activeClass === 'portfolio' ? "active" : ''}`}>
              {" "}
              <Link to="/#portfolio">Portfolio</Link>
            </li>
            <li onClick={() => { scrollToDiv("ourProcess"); setShowNavbar(false); setActiveName("") }} className={`${activeClass === 'ourProcess' ? "active" : ''}`}>
              {" "}
              <Link to="/#ourProcess">Our process</Link>
            </li>
            <li onClick={() => { scrollToDiv("price"); setShowNavbar(false); setActiveName("") }} className={`${activeClass === 'price' ? "active" : ''}`}>
              <Link to="/#price">
                Price
              </Link>
            </li>
            <li onClick={() => { scrollToDiv("go-to-calender"); setShowNavbar(false); setActiveName("") }} className={`${activeClass === 'go-to-calender' ? "active" : ''}`}>Contact Us</li>
            <li onClick={() => { scrollToDiv("faqs"); setShowNavbar(false); setActiveName("") }} className={`${activeClass === 'faqs' ? "active" : ''}`}>
              <Link to="/faq">FAQ</Link>{" "}
            </li>
          </ul>
        </div>
        <div className={`${showNavbar ? "btnContact" : "ml-16 hide-btn"}  transtion-05`}>
          <a href="tel:(307) 417-0652" className="primary-button d-flex align-items-center flex-row justify-content-around">
            <img src={phoneIcon} alt="phoneIcon" className="phoneIco" loading='lazy' />
            (307) 417-0652
          </a>
        </div>
      </div>

      <div onClick={handleShowNavbar} className="mobile-menu">
        <img src={hambugerIcon} alt="phoneIcon" className="hambugerIcon" loading='lazy' />
      </div>
    </div>
  );
};

export default Header;
