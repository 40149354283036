import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Limage6 from 'src/Assests/hero-section/12.png'
import image6 from 'src/Assests/hero-section/9.png'
import FixedForm from '../base/fixedForm'
import PricingOne from './PricingOne'
import Calendar from './calendar'
import HeroSection from './hero-section'
import Projects from './projects'
import SalesDetail from './sales'
import Stats from './stats'
import Testimonial from './testimonial'
import Timeline from './timeline'


const Home = ({ activeName }) => {
    const [open, setOpen] = useState(false)
    const [isBodyOverflowHidden, setIsBodyOverflowHidden] = useState(false)
    const [data, setData] = useState('')

    useEffect(() => {
        if (open) {
            // Disable body scrolling when the box is open
            document.body.style.overflow = 'hidden';
            setIsBodyOverflowHidden(true);
        } else {
            // Enable body scrolling when the box is closed
            document.body.style.overflow = 'auto';
            setIsBodyOverflowHidden(false);
        }

        return () => {
            // Cleanup: enable body scrolling on component unmount
            document.body.style.overflow = 'auto';
        };
    }, [open]);

    return (
        <div >
            <Helmet>
                <title>Leading Software Agency | SoftlogicApp</title>
                <meta
                    name="description"
                    content="Leading software agency providing app development, web development, digital marketing, logo designing, UI/UX designing, and SEO services. Contact us today for a free consultation!"
                />
                <meta
                    name="keywords"
                    content="software agency, softlogicapp, app developers, web development services, digital marketing, logo designing, UI/UX designing, SEO services"
                />
            </Helmet>
            {open && <FixedForm setOpen={setOpen} data={data} />}
            <HeroSection laptop={Limage6} mob={image6} />
            <Projects />
            <Stats />
            <SalesDetail />
            <Timeline />
            <Testimonial />
            <PricingOne setOpen={setOpen} setData={setData} activeName={activeName} />
            <Calendar />
        </div>
    )
}

export default Home