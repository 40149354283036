import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';

function Stats() {
  const statsRef = useRef(null);

  const animateCounter = (counterElement, targetNumber, duration) => {
    const startNumber = parseInt(counterElement.innerText);
    const increment = Math.ceil((targetNumber - startNumber) / (duration * 60)); // 60 frames per second
    let currentNumber = startNumber;
    const interval = setInterval(() => {
      currentNumber += increment;
      counterElement.innerText = currentNumber;
      if ((increment > 0 && currentNumber >= targetNumber) || (increment < 0 && currentNumber <= targetNumber)) {
        clearInterval(interval);
        counterElement.innerText = targetNumber;
      }
    }, 2000 / 60); // 60 frames per second
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const counterNumbers = statsRef.current.querySelectorAll('.counter-numbers');
          const maxDuration = 3; // Maximum duration for the animation in seconds
          const totalNumbers = counterNumbers.length;
          const duration = maxDuration / totalNumbers;

          counterNumbers.forEach((counter, index) => {
            const targetNumber = parseInt(counter.dataset.number);
            animateCounter(counter, targetNumber, duration);
          });

          observer.unobserve(entry.target);
        }
      });
    });

    observer.observe(statsRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);


  return (
    <section className="section_numbers" ref={statsRef}>
      <Helmet>
        <title>Our Achievements | SoftlogicApp</title>
        <meta
          name="description"
          content="Over 150 successful projects, 100+ satisfied clients, and extensive industry experience. See our project statistics and achievements."
        />
        <meta
          name="keywords"
          content="successful projects, satisfied clients, industry experience, project statistics"
        />
      </Helmet>
      <div className="padding-global">
        <div className="container-large">
          <div className="padding-section-xlarge">
            <div className="section_header is-no-bottom text-align-center text-center">
              <div aria-hidden="true" className="numbers_grid">
                <div className="numbers_item">
                  <div className="numbers_text">
                    <span className="text-span counter-numbers" role="marquee" aria-roledescription="Number count animation from 150 to 200" data-number="200">150</span>
                    <sup className="numbers_super is-v2">+</sup>
                  </div>
                  <div className="text-size-large ">Projects Finished</div>
                </div>
                <div className="numbers_item">
                  <div className="numbers_text">
                    <span className="counter-numbers" role="marquee" aria-roledescription="Number count animation from 0 to 5" data-number="5">0</span>
                    <sup className="numbers_super">+</sup>
                  </div>
                  <div className="text-size-large">Years of Experience</div>
                </div>
                <div className="numbers_item">
                  <div className="numbers_text">
                    <span className="counter-numbers" role="marquee" aria-roledescription="Number count animation from 100 to 150" data-number="150">100</span>
                    <sup className="numbers_super is-v3">+</sup>
                  </div>
                  <div className="text-size-large">Clients Worldwide</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="numbers_screen">
        <div class="numbers_bg-grad"></div>
        <div class="numbers_grad"></div>
        <div class="numbers_grad is-right"></div>
        <div className="numbers_screen-left">
          <div className="numbers_screen-box-left">
            <img
              src="/images1/images2/Ecommerce-Website/3.png"
              loading="lazy"
              // srcSet="project8 500w, project8 800w, main-assets/644e6303d74e6139e80de504_BG Shots-5-p-1080.jpg 1080w, main-assets/644e6303d74e6139e80de504_BG Shots-5.jpg 1440w"
              sizes="(max-width: 991px) 100vw, 34vw"
              alt=""
              className="numbers_image"
            />
            <img
              src="/images1/images2/CA.png"
              loading="lazy"
              // srcSet="main-assets/644e630344c536ff8f9d1eb7_BG Shots-18-p-500.jpg 500w, main-assets/644e630344c536ff8f9d1eb7_BG Shots-18-p-800.jpg 800w, main-assets/644e630344c536ff8f9d1eb7_BG Shots-18-p-1080.jpg 1080w, main-assets/644e630344c536ff8f9d1eb7_BG Shots-18.jpg 1440w"
              sizes="(max-width: 991px) 100vw, 9vw"
              alt=""
              className="numbers_image"
            />
            <img
              src="/images1/images2/Mob/3a.png"
              loading="lazy"
              // srcSet="main-assets/644e63038bde350248308e93_BG Shots-15-p-500.jpg 500w, main-assets/644e63038bde350248308e93_BG Shots-15-p-800.jpg 800w, main-assets/644e63038bde350248308e93_BG Shots-15-p-1080.jpg 1080w, main-assets/644e63038bde350248308e93_BG Shots-15.jpg 1440w"
              sizes="(max-width: 991px) 100vw, 6vw"
              alt=""
              className="numbers_image"
            />
            <img
              src="/images1/images2/Ecommerce-Website/2.png"
              loading="lazy"
              // srcSet="main-assets/644e63037cd2c377c64a2bcb_BG Shots-8-p-500.jpg 500w, main-assets/644e63037cd2c377c64a2bcb_BG Shots-8-p-800.jpg 800w, main-assets/644e63037cd2c377c64a2bcb_BG Shots-8-p-1080.jpg 1080w, main-assets/644e63037cd2c377c64a2bcb_BG Shots-8.jpg 1440w"
              sizes="(max-width: 991px) 100vw, 4vw"
              alt=""
              className="numbers_image"
            />
            <img
              src="/images1/images2/Mob/2.png"
              loading="lazy"
              // srcSet="main-assets/6458a1842835a8764a4d8b21_Open Graph Image Pricing-p-500.jpg 500w, main-assets/6458a1842835a8764a4d8b21_Open Graph Image Pricing-p-800.jpg 800w, main-assets/6458a1842835a8764a4d8b21_Open Graph Image Pricing-p-1080.jpg 1080w, main-assets/6458a1842835a8764a4d8b21_Open Graph Image Pricing.jpg 1440w"
              sizes="(max-width: 991px) 100vw, 3vw"
              alt="Open Graph Image Pricing"
              className="numbers_image"
            />
          </div>
          <div className="numbers_screen-box-left">
            <img
              src="/images1/images2/Ecommerce-Website/3.png"
              loading="lazy"
              // srcSet="main-assets/644e6303d74e6139e80de504_BG Shots-5-p-500.jpg 500w, main-assets/644e6303d74e6139e80de504_BG Shots-5-p-800.jpg 800w, main-assets/644e6303d74e6139e80de504_BG Shots-5-p-1080.jpg 1080w, main-assets/644e6303d74e6139e80de504_BG Shots-5.jpg 1440w"
              sizes="(max-width: 991px) 100vw, 34vw"
              alt=""
              className="numbers_image"
            />
            <img
              src="/images1/images2/Mob/1.png"
              loading="lazy"
              // srcSet="main-assets/644e630344c536ff8f9d1eb7_BG Shots-18-p-500.jpg 500w, main-assets/644e630344c536ff8f9d1eb7_BG Shots-18-p-800.jpg 800w, main-assets/644e630344c536ff8f9d1eb7_BG Shots-18-p-1080.jpg 1080w, main-assets/644e630344c536ff8f9d1eb7_BG Shots-18.jpg 1440w"
              sizes="(max-width: 991px) 100vw, 9vw"
              alt=""
              className="numbers_image"
            />
            <img
              src="/images1/images2/Mob/3a.png"
              loading="lazy"
              // srcSet="main-assets/644e63038bde350248308e93_BG Shots-15-p-500.jpg 500w, main-assets/644e63038bde350248308e93_BG Shots-15-p-800.jpg 800w, main-assets/644e63038bde350248308e93_BG Shots-15-p-1080.jpg 1080w, main-assets/644e63038bde350248308e93_BG Shots-15.jpg 1440w"
              sizes="(max-width: 991px) 100vw, 6vw"
              alt=""
              className="numbers_image"
            />
            <img
              src="/images1/images2/Ecommerce-Website/2.png"
              loading="lazy"
              // srcSet="main-assets/644e63037cd2c377c64a2bcb_BG Shots-8-p-500.jpg 500w, main-assets/644e63037cd2c377c64a2bcb_BG Shots-8-p-800.jpg 800w, main-assets/644e63037cd2c377c64a2bcb_BG Shots-8-p-1080.jpg 1080w, main-assets/644e63037cd2c377c64a2bcb_BG Shots-8.jpg 1440w"
              sizes="(max-width: 991px) 100vw, 4vw"
              alt=""
              className="numbers_image"
            />
            <img
              src="/images1/images2/3.png"
              loading="lazy"
              // srcSet="main-assets/6458a1842835a8764a4d8b21_Open Graph Image Pricing-p-500.jpg 500w, main-assets/6458a1842835a8764a4d8b21_Open Graph Image Pricing-p-800.jpg 800w, main-assets/6458a1842835a8764a4d8b21_Open Graph Image Pricing-p-1080.jpg 1080w, main-assets/6458a1842835a8764a4d8b21_Open Graph Image Pricing.jpg 1440w"
              sizes="(max-width: 991px) 100vw, 3vw"
              alt="Open Graph Image Pricing"
              className="numbers_image"
            />
          </div>
        </div>
        <div className="numbers_screen-right">
          <div className="numbers_screen-box-right">
            <img
              src="/images1/images2/Ecommerce-Website/4.png"
              loading="lazy"
              // srcSet="main-assets/644e63028bde353618308e46_BG Shots-17-p-500.jpg 500w, main-assets/644e63028bde353618308e46_BG Shots-17-p-800.jpg 800w, main-assets/644e63028bde353618308e46_BG Shots-17-p-1080.jpg 1080w, main-assets/644e63028bde353618308e46_BG Shots-17.jpg 1440w"
              sizes="(max-width: 991px) 100vw, 3vw"
              alt=""
              className="numbers_image"
            />
            <img
              src="/images1/images2/Ecommerce-Website/1.png"
              loading="lazy"
              // srcSet="main-assets/644e63020aef43b1202b6c16_BG Shots-3-p-500.jpg 500w, main-assets/644e63020aef43b1202b6c16_BG Shots-3-p-800.jpg 800w, main-assets/644e63020aef43b1202b6c16_BG Shots-3-p-1080.jpg 1080w, main-assets/644e63020aef43b1202b6c16_BG Shots-3.jpg 1440w"
              sizes="(max-width: 991px) 100vw, 4vw"
              alt=""
              className="numbers_image"
            />
            <img
              src="/images1/images2/Ecommerce-Website/5.png"
              loading="lazy"
              // srcSet="main-assets/644e63031f1a3600bf6cc2ee_BG Shots-2-p-500.jpg 500w, main-assets/644e63031f1a3600bf6cc2ee_BG Shots-2-p-800.jpg 800w, main-assets/644e63031f1a3600bf6cc2ee_BG Shots-2-p-1080.jpg 1080w, main-assets/644e63031f1a3600bf6cc2ee_BG Shots-2.jpg 1440w"
              sizes="(max-width: 991px) 100vw, 6vw"
              alt=""
              className="numbers_image"
            />
            <img
              src="/images1/images2/Mob/PT.png"
              loading="lazy"
              // srcSet="main-assets/644e6303c3356e1f5e66ae0d_BG Shots-7-p-500.jpg 500w, main-assets/644e6303c3356e1f5e66ae0d_BG Shots-7-p-800.jpg 800w, main-assets/644e6303c3356e1f5e66ae0d_BG Shots-7-p-1080.jpg 1080w, main-assets/644e6303c3356e1f5e66ae0d_BG Shots-7.jpg 1440w"
              sizes="(max-width: 991px) 100vw, 9vw"
              alt=""
              className="numbers_image"
            />
            <img
              src="/images1/images2/Ecommerce-Website/6.png"
              loading="lazy"
              // srcSet="main-assets/644e630329bd36bd8d345c16_BG Shots-6-p-500.jpg 500w, main-assets/644e630329bd36bd8d345c16_BG Shots-6-p-800.jpg 800w, main-assets/644e630329bd36bd8d345c16_BG Shots-6-p-1080.jpg 1080w, main-assets/644e630329bd36bd8d345c16_BG Shots-6.jpg 1440w"
              sizes="(max-width: 991px) 100vw, 34vw"
              alt=""
              className="numbers_image"
            />
          </div>
          <div className="numbers_screen-box-right">
            <img
              src="/images1/images2/Ecommerce-Website/4.png"
              loading="lazy"
              // srcSet="main-assets/644e63028bde353618308e46_BG Shots-17-p-500.jpg 500w, main-assets/644e63028bde353618308e46_BG Shots-17-p-800.jpg 800w, main-assets/644e63028bde353618308e46_BG Shots-17-p-1080.jpg 1080w, main-assets/644e63028bde353618308e46_BG Shots-17.jpg 1440w"
              sizes="(max-width: 991px) 100vw, 3vw"
              alt=""
              className="numbers_image"
            />
            <img
              src="/images1/images2/Ecommerce-Website/1.png"
              loading="lazy"
              // srcSet="main-assets/644e63020aef43b1202b6c16_BG Shots-3-p-500.jpg 500w, main-assets/644e63020aef43b1202b6c16_BG Shots-3-p-800.jpg 800w, main-assets/644e63020aef43b1202b6c16_BG Shots-3-p-1080.jpg 1080w, main-assets/644e63020aef43b1202b6c16_BG Shots-3.jpg 1440w"
              sizes="(max-width: 991px) 100vw, 4vw"
              alt=""
              className="numbers_image"
            />
            <img
              src="/images1/images2/Ecommerce-Website/5.png"
              loading="lazy"
              // srcSet="main-assets/644e63031f1a3600bf6cc2ee_BG Shots-2-p-500.jpg 500w, main-assets/644e63031f1a3600bf6cc2ee_BG Shots-2-p-800.jpg 800w, main-assets/644e63031f1a3600bf6cc2ee_BG Shots-2-p-1080.jpg 1080w, main-assets/644e63031f1a3600bf6cc2ee_BG Shots-2.jpg 1440w"
              sizes="(max-width: 991px) 100vw, 6vw"
              alt=""
              className="numbers_image"
            />
            <img
              src="/images1/images2/Mob/4.png"
              loading="lazy"
              // srcSet="main-assets/644e6303c3356e1f5e66ae0d_BG Shots-7-p-500.jpg 500w, main-assets/644e6303c3356e1f5e66ae0d_BG Shots-7-p-800.jpg 800w, main-assets/644e6303c3356e1f5e66ae0d_BG Shots-7-p-1080.jpg 1080w, main-assets/644e6303c3356e1f5e66ae0d_BG Shots-7.jpg 1440w"
              sizes="(max-width: 991px) 100vw, 9vw"
              alt=""
              className="numbers_image"
            />
            <img
              src="/images1/images2/Ecommerce-Website/6.png"
              loading="lazy"
              // srcSet="main-assets/644e630329bd36bd8d345c16_BG Shots-6-p-500.jpg 500w, main-assets/644e630329bd36bd8d345c16_BG Shots-6-p-800.jpg 800w, main-assets/644e630329bd36bd8d345c16_BG Shots-6-p-1080.jpg 1080w, main-assets/644e630329bd36bd8d345c16_BG Shots-6.jpg 1440w"
              sizes="(max-width: 991px) 100vw, 34vw"
              alt=""
              className="numbers_image"
            />
          </div>
        </div>
      </div>
      <div className="glass-lining_wrapper">
        <div className="glass-lining_div is-bottom"></div>
        <div className="glass-lining_div is-top"></div>
      </div>
    </section>
  )
}

export default Stats