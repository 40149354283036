
import { useState } from 'react'
import toast from 'react-hot-toast'
import { API } from 'src/network/API'
import InputField from "../base/inputField"
import TextArea from '../base/textArea'
//Font based
import FontBased2 from 'src/Assests/logo-images/font-based/image 19.png'
import FontBased3 from 'src/Assests/logo-images/font-based/image 20.png'
import FontBased4 from 'src/Assests/logo-images/font-based/image 21.png'
import FontBased5 from 'src/Assests/logo-images/font-based/image 22.png'
import FontBased1 from 'src/Assests/logo-images/font-based/image18.png'

//Icon based
import IconBased1 from 'src/Assests/logo-images/icon-based/image 23.png'
import IconBased2 from 'src/Assests/logo-images/icon-based/image 24.png'
import IconBased3 from 'src/Assests/logo-images/icon-based/image 25.png'
import IconBased4 from 'src/Assests/logo-images/icon-based/image 26.png'
import IconBased5 from 'src/Assests/logo-images/icon-based/image 27.png'
import IconBased6 from 'src/Assests/logo-images/icon-based/image 28.png'
import IconBased7 from 'src/Assests/logo-images/icon-based/image 29.png'
import IconBased8 from 'src/Assests/logo-images/icon-based/image 30.png'
import IconBased9 from 'src/Assests/logo-images/icon-based/image 31.png'

//Color based
import ColorLogo1 from 'src/Assests/logo-images/color-logos/image 32.png'
import ColorLogo2 from 'src/Assests/logo-images/color-logos/image 33.png'
import ColorLogo3 from 'src/Assests/logo-images/color-logos/image 34.png'
import ColorLogo4 from 'src/Assests/logo-images/color-logos/image 35.png'
import ColorLogo5 from 'src/Assests/logo-images/color-logos/image 36.png'
import ColorLogo6 from 'src/Assests/logo-images/color-logos/image 37.png'
import ColorLogo7 from 'src/Assests/logo-images/color-logos/image 38.png'
import ColorLogo8 from 'src/Assests/logo-images/color-logos/image 39.png'
import ColorLogo9 from 'src/Assests/logo-images/color-logos/image 40.png'
import ColorLogo10 from 'src/Assests/logo-images/color-logos/image 59.png'
import ColorLogo11 from 'src/Assests/logo-images/color-logos/image 60.png'

//FontStyles
import FontStyle1 from 'src/Assests/logo-images/font-logos/image 41.png'
import FontStyle2 from 'src/Assests/logo-images/font-logos/image 42.png'
import FontStyle3 from 'src/Assests/logo-images/font-logos/image 43.png'
import FontStyle4 from 'src/Assests/logo-images/font-logos/image 44.png'
import FontStyle5 from 'src/Assests/logo-images/font-logos/image 45.png'
import FontStyle6 from 'src/Assests/logo-images/font-logos/image 46.png'
import FontStyle7 from 'src/Assests/logo-images/font-logos/image 47.png'
import FontStyle8 from 'src/Assests/logo-images/font-logos/image 48.png'
import FontStyle9 from 'src/Assests/logo-images/font-logos/image 49.png'
import FontStyle10 from 'src/Assests/logo-images/font-logos/image 61.png'
import FontStyle11 from 'src/Assests/logo-images/font-logos/image 62.png'
import FontStyle12 from 'src/Assests/logo-images/font-logos/image 63.png'
import FontStyle13 from 'src/Assests/logo-images/font-logos/image 64.png'
import FontStyle14 from 'src/Assests/logo-images/font-logos/image 65.png'
import FontStyle15 from 'src/Assests/logo-images/font-logos/image 66.png'

//Look logos
import LookLogos1 from 'src/Assests/logo-images/look-logos/image 50.png'
import LookLogos2 from 'src/Assests/logo-images/look-logos/image 51.png'
import LookLogos3 from 'src/Assests/logo-images/look-logos/image 52.png'
import LookLogos4 from 'src/Assests/logo-images/look-logos/image 53.png'
import LookLogos5 from 'src/Assests/logo-images/look-logos/image 54.png'
import LookLogos6 from 'src/Assests/logo-images/look-logos/image 55.png'
import LookLogos7 from 'src/Assests/logo-images/look-logos/image 56.png'
import LookLogos8 from 'src/Assests/logo-images/look-logos/image 57.png'
import LookLogos9 from 'src/Assests/logo-images/look-logos/image 58.png'
import LookLogos10 from 'src/Assests/logo-images/look-logos/image 67.png'
import LookLogos11 from 'src/Assests/logo-images/look-logos/image 68.png'
import LookLogos12 from 'src/Assests/logo-images/look-logos/image 69.png'
import { generateAndSendPDF } from 'src/utils/functions'


const data = [
    {
        title: "FONT BASED LOGOS",
        images: [
            { image: FontBased1, text: "Just Font" },
            { image: FontBased2, text: "Handmade" },
            { image: FontBased3, text: "Font + Meaning" },
            { image: FontBased4, text: "Initials" },
            { image: FontBased5, text: "Font in Shape" },
        ]
    },
    {
        title: "ICON BASED LOGOS",
        images: [
            { image: IconBased1, text: "Abstract Graphic" },
            { image: IconBased2, text: "Silhouette" },
            { image: IconBased3, text: "Geometric Symbol" },
            { image: IconBased4, text: "Illustrated Symbol" },
            { image: IconBased5, text: "Illustration" },
            { image: IconBased6, text: "Seals and Crests" },
            { image: IconBased7, text: "Mascot" },
            { image: IconBased8, text: "E-sports" },
            { image: IconBased9, text: "3D Logo" }
        ]
    },
    {
        title: "COLORS TO EXPLORE",
        images: [
            { image: ColorLogo1, text: "Blue" },
            { image: ColorLogo2, text: "Aqua" },
            { image: ColorLogo3, text: "Green" },
            { image: ColorLogo4, text: "Purple" },
            { image: ColorLogo5, text: "Pink" },
            { image: ColorLogo6, text: "Red" },
            { image: ColorLogo7, text: "Orange" },
            { image: ColorLogo8, text: "Yellow" },
            { image: ColorLogo9, text: "Light Neutrals" },
            { image: ColorLogo10, text: "Dark Neutrals" },
            { image: ColorLogo11, text: "Designer's Choice" }
        ]
    },
    {
        title: "FONT STYLE",
        images: [
            { image: FontStyle1, text: "Slim" },
            { image: FontStyle2, text: "Rounded" },
            { image: FontStyle3, text: "Bolder" },
            { image: FontStyle4, text: "Serif" },
            { image: FontStyle5, text: "San Serif" },
            { image: FontStyle6, text: "Calligraphy" },
            { image: FontStyle7, text: "Script" },
            { image: FontStyle8, text: "Techno" },
            { image: FontStyle9, text: "Retro" },
            { image: FontStyle10, text: "Comic" },
            { image: FontStyle11, text: "Handwritten" },
            { image: FontStyle12, text: "Graffiti" },
            { image: FontStyle13, text: "Typewriter" },
            { image: FontStyle14, text: "Stencil" },
            { image: FontStyle15, text: "Decorative" }
        ]
    },
    {
        title: "LOOK AND FEEL",
        images: [
            { image: LookLogos1, text: "Artistic" },
            { image: LookLogos2, text: "Minimal" },
            { image: LookLogos3, text: "Sophisticated" },
            { image: LookLogos4, text: "Corporate" },
            { image: LookLogos5, text: "Web 2.0" },
            { image: LookLogos6, text: "Retro" },
            { image: LookLogos7, text: "Fun" },
            { image: LookLogos8, text: "Hi-Tech" },
            { image: LookLogos9, text: "Feminine" },
            { image: LookLogos10, text: "Masculine" },
            { image: LookLogos11, text: "Sealed" },
            { image: LookLogos12, text: "Traditional" }
        ]
    },
];
export const LogoFrom = () => {
    const [selectedLogos, setSelectedLogos] = useState({});
    const [image, setImage] = useState(null);
    const [formData, setFormData] = useState({
        contactName: '',
        jobTitle: '',
        companyName: '',
        email: '',
        phoneNumber: '',
        webAddress: '',
        wording: '',
        meaning: '',
        slogan: '',
        objectsImages: '',
        description: '',
        competitors: '',
        logosLike: '',
        logosDislike: '',
        dos: '',
        donts: '',
        additionalInfo: '',
        name: '',
        personalEmail: '',
        designation: '',
        personalPhone: '',
        companyAddress: '',
        companyPhone: '',
        file: null
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false)

    const handleCheckboxChange = (category, itemText, isChecked) => {
        setSelectedLogos(prev => {
            const updatedCategory = prev[category] ? [...prev[category]] : [];
            if (isChecked) {
                // Add the item if the checkbox is checked and it's not already in the array
                if (!updatedCategory.includes(itemText)) {
                    updatedCategory.push(itemText);
                }
            } else {
                // Remove the item if the checkbox is unchecked
                const index = updatedCategory.indexOf(itemText);
                if (index > -1) {
                    updatedCategory.splice(index, 1);
                }
            }
            return { ...prev, [category]: updatedCategory };
        });
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0]; // Get the first file from the file input
        if (file) {
            const uploaded = new FormData();
            uploaded.append('file', file);
            await API.uploadFile(uploaded)
                .then(res => {
                    toast.success(`${file?.name} uploaded successfully`)
                    setImage(res?.filename)
                })
                .catch(err => toast.error(err?.message))
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        let newErrors = {}
        if (!formData.contactName) newErrors.contactName = "Contact Name is required"
        if (!formData.jobTitle) newErrors.jobTitle = "Job Title is required"
        if (!formData.email) newErrors.email = "Email is required"
        if (!formData.phoneNumber) newErrors.phoneNumber = 'Contact Phone Number is required.';
        // else if (!/^\+(?:[0-9]●?){6,14}[0-9]$/.test(formData.phoneNumber)) newErrors.phoneNumber = 'Invalid phone number format.';

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors)
            toast.error("Please fill the required fields");
            setLoading(false)
            return
        }

        const extendedData = { ...formData, selectedLogos }
        const data = { name: extendedData.name, email: extendedData.email, formData: extendedData, userImage: image }
        const isSubmitted = await generateAndSendPDF(data, "Logo form")
        handleReset()
        setLoading(false)
    };

    const handleReset = () => {
        setFormData({
            contactName: '',
            jobTitle: '',
            companyName: '',
            email: '',
            phoneNumber: '',
            webAddress: '',
            wording: '',
            meaning: '',
            slogan: '',
            objectsImages: '',
            description: '',
            competitors: '',
            logosLike: '',
            logosDislike: '',
            dos: '',
            donts: '',
            additionalInfo: '',
            name: '',
            personalEmail: '',
            designation: '',
            personalPhone: '',
            companyAddress: '',
            companyPhone: '',
            file: null
        })

        setImage('')
        setSelectedLogos({})
    }


    return (
        <div className='form'>
            <div className='form-info'>
                <h1 className='heading'>Logo Design Questionnaire</h1>
                <p className='text'>Tell us about your brand, and we'll create a logo that tells your story</p>
            </div>
            <div className="detail1">
                <div class="div1">
                    <InputField label={"Contact Name"} required={true} placeholder={"Name"}
                        name="contactName"
                        value={formData.contactName}
                        onChange={handleChange}
                    />
                    {errors.contactName && <p className='error'>{errors.contactName}</p>}
                </div>
                <div class="div2">
                    <InputField label={"Job Title"} required={true} placeholder={"Postion"}
                        name="jobTitle"
                        value={formData.jobTitle}
                        onChange={handleChange}
                    />
                    {errors.jobTitle && <p className='error'>{errors.jobTitle}</p>}

                </div>
                <div class="div3">
                    <InputField
                        label={"Company Name"}
                        placeholder={"Type Company Name"}
                        name="companyName"
                        value={formData.companyName}
                        onChange={handleChange}
                    />
                </div>
                <div class="div4">
                    <InputField label={"Email"} type={'email'} required={true} placeholder={"Type Your Email"}
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    {errors.email && <p className='error'>{errors.email}</p>}
                </div>
                <div class="div5">
                    <InputField label={"Phone Number"}
                        type={'number'}
                        required={true}
                        placeholder={"(-)(-)(-)"}
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                    />
                    {errors.phoneNumber && <p className='error'>{errors.phoneNumber}</p>}
                </div>
                <div class="div6">
                    <InputField label={"Web Address"} placeholder={"Type Your Web Address"}
                        name="webAddress"
                        value={formData.webAddress}
                        onChange={handleChange}

                    />
                </div>
                <div class="div7">
                    <TextArea label={"Exact wording to be used on the logo Example:"} subtitle={"Walkers OR Walker and Son Limited OR Walkers, Boots for people going places."}
                        name="wording"
                        value={formData.wording}
                        onChange={handleChange}

                    />
                </div>
                <div class="div8">
                    <TextArea label={"Do the words in your Company name have a specific meaning ?"}
                        name="meaning"
                        value={formData.meaning}
                        onChange={handleChange}
                    />
                </div>
                <div class="div9">
                    <TextArea label={"Slogan / motto if you want to include"}
                        name="slogan"
                        value={formData.slogan}
                        onChange={handleChange}

                    />
                </div>
                <div class="div10">
                    <TextArea label={"Objects or images you want to include"}
                        name="objectsImages"
                        value={formData.objectsImages}
                        onChange={handleChange}

                    />
                </div>
                <div class="div11">
                    <TextArea label={"Short description of product / service / organization"}
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        row={2}
                        column={2}
                    />
                </div>
                <div class="div12">
                    <TextArea label={"Competitors and similar business"}
                        subtitle={"If you would like us to look at any of your competitors web sites or those who have similar business to yours If you would like us to look at any of your competitors web sites or those who have similar business to yours"}
                        row={2} column={2}
                        name="competitors"
                        value={formData.competitors}
                        onChange={handleChange}

                    />
                </div>
                <div class="div13">
                    <TextArea label={"Logos you like and why ?"}
                        subtitle={"Providing examples of logos you like will help us understand your taste better and also the style you are looking for"}
                        name="logosLike"
                        value={formData.logosLike}
                        onChange={handleChange}

                    />
                </div>
                <div class="div14">
                    <TextArea label={"Logos you dislike and why? Provide us the logos you dislike and tell us why."}
                        name="logosDislike"
                        value={formData.logosDislike}
                        onChange={handleChange}

                    />
                </div>
                <div class="div15">
                    <TextArea label={"Do's"} row={2} column={2}
                        name="dos"
                        value={formData.dos}
                        onChange={handleChange}

                    />
                </div>
                <div class="div16">
                    <TextArea
                        label={"Dont's"} row={2} column={2}
                        name="donts"
                        value={formData.donts}
                        onChange={handleChange}

                    />
                </div>
                <div class="div17">
                    <TextArea
                        label={"Any other information you consider important to mention"} row={2} column={2}
                        name="additionalInfo"
                        value={formData.additionalInfo}
                        onChange={handleChange}

                    />
                </div>
            </div>
            <div className='detail2'>
                <div className='form-info'>
                    <h1 className='heading'>Which designs do you like?</h1>
                    <p className='text'>Let's start by helping the designers understand which styles you prefer.</p>
                </div>
                {data.map((elem, categoryIndex) => (
                    <div className='logo-types' key={categoryIndex}>
                        <div className='logos'>
                            {elem.images.map((logo, logoIndex) => (
                                <div className='logo-box' key={logoIndex}>
                                    <label className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            className="logo-check"
                                            onChange={(e) => handleCheckboxChange(elem.title, logo.text, e.target.checked)}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                    <img src={logo.image} className='logo-img' alt={logo.text} />
                                    <div className='text-box'>
                                        <p className='logo-text'>{logo.text}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}


            </div>

            <h1 className='detail3-info'>Business Card Details</h1>

            <div class="detail3">
                <div class="detail3-div1">
                    <InputField
                        label={"Name"}
                        placeholder={"Type Your Name..."}
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </div>
                <div class="detail3-div2">
                    <InputField
                        label={"Email"}
                        type={'email'}
                        placeholder={"Type Your Name..."}
                        name="personalEmail"
                        value={formData.personalEmail}
                        onChange={handleChange}
                    />
                </div>
                <div class="detail3-div3">
                    <InputField
                        label={"Designation"}
                        placeholder={"Type Your Designation..."}
                        name="designation"
                        value={formData.designation}
                        onChange={handleChange}
                    />
                </div>
                <div class="detail3-div4">
                    <InputField
                        label={"Personal Phone Number"}
                        placeholder={"Type Your Phone no..."}
                        name="personalPhone"
                        value={formData.personalPhone}
                        onChange={handleChange}
                    />
                </div>
                <div class="detail3-div5">
                    <InputField
                        label={"Company Address"}
                        placeholder={"Type Your Address..."}
                        name="companyAddress"
                        value={formData.companyAddress}
                        onChange={handleChange}
                    />
                </div>
                <div class="detail3-div6">
                    <InputField
                        label={"Company Phone Number"}
                        placeholder={"Type Your Company Phone No..."}
                        name="companyPhone"
                        value={formData.companyPhone}
                        onChange={handleChange}
                    />
                </div>
                <div class="detail3-div7">
                    <input
                        type="file"
                        onChange={handleImageChange}
                        className='file-upload'
                    />

                    <p className='file-info'>Max. file size: 50 MB.</p>
                </div>
                <div class="detail3-div8">
                    <button className='submit-button' onClick={handleSubmit} disabled={loading}>Submit</button>
                    {/* <button className='cont-button'>Save And Continue Later</button> */}
                </div>
            </div>

        </div>
    )
}
