import { useState } from 'react';


const Checkbox = ({ label, yesLabel = "Yes", noLabel = "No", onChange, required, name }) => {
    // State to track whether checkbox is checked
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (e) => {
        const newChecked = !isChecked;
        setIsChecked(newChecked);
        if (onChange) {
            onChange(e); // Pass the event object to the onChange handler
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: "20px" }}>
            <p>{label} {required ? <span style={{ color: "#a71922", fontWeight: 700 }}>(Required)</span> : ''}</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* Checkbox with tick */}
                <div className='logo-box'>
                    <label className="checkbox-container">
                        <input
                            type="checkbox"
                            className="logo-check"
                            name={name}
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                        <span className="checkmark"></span>
                    </label>
                </div>
                <p>{yesLabel}</p>
            </div>

            {/* Checkbox with cross */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className='logo-box' >
                    <label className="checkbox-container">
                        <input
                            type="checkbox"
                            className="logo-check"
                            checked={!isChecked}
                            name={name}
                            onChange={handleCheckboxChange}
                        />
                        <span className="cross-mark"></span>
                    </label>
                </div>
                <p>{noLabel}</p>
            </div>
        </div>
    );
};

export default Checkbox;
