import { useState } from "react";
import toast from "react-hot-toast";

import { generateAndSendPDF } from 'src/utils/functions';
import InputField from "../base/inputField";
import TextArea from '../base/textArea';

const SeoForm = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        businessObjectives: '',
        specificProducts: '',
        keywords: '',
        competitors: '',
        localSEO: '',
        geographicAreas: '',
        conversionSuccess: '',
        kpis: ''
    });
    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        // Validate form fields
        const newErrors = {};
        if (!name) newErrors.name = 'Name is required.';
        if (!email) newErrors.email = 'Email is required.';
        if (!formData.businessObjectives) newErrors.businessObjectives = 'Business objectives are required.';
        if (!formData.specificProducts) newErrors.specificProducts = 'Specific products are required.';
        if (!formData.keywords) newErrors.keywords = 'Keywords are required.';
        if (!formData.competitors) newErrors.competitors = 'Competitors are required.';
        if (!formData.localSEO) newErrors.localSEO = 'Local SEO information is required.';
        // Add validations for other fields

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            toast.error("Please fill the required fields");
            setLoading(false)
            return;
        }

        // Clear previous errors if form is valid
        setErrors({});

        const isSubmitted = await generateAndSendPDF({ name, email, formData }, "SEO").then(res => {
            setFormData({
                businessObjectives: '',
                specificProducts: '',
                keywords: '',
                competitors: '',
                localSEO: '',
                geographicAreas: '',
                conversionSuccess: '',
                kpis: ''
            });
            setLoading(false)
        }

        )
    }

    //     if (isSubmitted) {
    //         // Clear form data after submission
    // };



    return (
        <div className='seo-form'>
            <div className='seo-form-info'>
                <h1 className='heading'>SEO Questionnaire</h1>
                <p className='text'>Help us understand your goals and we'll develop a custom SEO strategy</p>
            </div>
            <div className="seo-detail1">
                <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                        <InputField label={"Name"}
                            name="name"
                            value={name}
                            required
                            onChange={e => setName(e.target.value)}
                        />
                        {errors.name && <p className="error">{errors.name}</p>}

                    </div>
                    <div style={{ width: "50%" }}>
                        <InputField label={"Email"}
                            name="email"
                            type={"email"}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required
                        />
                        {errors.email && <p className="error">{errors.email}</p>}

                    </div>
                </div>
                <div >
                    <TextArea label={"What are your primary business objectives, and how do you see SEO contributing to them?"}
                        row={2}
                        column={2}
                        name="businessObjectives"
                        value={formData.businessObjectives}
                        onChange={handleChange}
                        required
                    />
                    {errors.businessObjectives && <p className="error">{errors.businessObjectives}</p>}
                </div>
                <div >
                    <TextArea label={"Are there specific products, services, or target markets you want to focus on?"} row={2}
                        column={2}
                        name="specificProducts"
                        value={formData.specificProducts}
                        onChange={handleChange}
                        required
                    />
                    {errors.specificProducts && <p className="error">{errors.specificProducts}</p>}
                </div>
                <div >
                    <TextArea label={"Have you identified specific keywords or phrases that you want to target in search engine rankings?"} row={2}
                        column={2}
                        name="keywords"
                        value={formData.keywords}
                        onChange={handleChange}
                        required
                    />
                    {errors.keywords && <p className="error">{errors.keywords}</p>}
                </div>
                <div >
                    <TextArea label={"Who are your main competitors, and what keywords are they ranking for?"} row={2}
                        column={2}
                        name="competitors"
                        value={formData.competitors}
                        onChange={handleChange}
                        required
                    />
                    {errors.competitors && <p className="error">{errors.competitors}</p>}
                </div>
                <div >
                    <TextArea label={"Do you have a local business that needs local SEO optimization?"} row={2}
                        column={2}
                        name="localSEO"
                        value={formData.localSEO}
                        onChange={handleChange}
                        required
                    />
                    {errors.localSEO && <p className="error">{errors.localSEO}</p>}
                </div>
                <div >
                    <TextArea label={"Are there specific geographic areas or locations you want to target?"} row={2}
                        column={2}
                        name="geographicAreas"
                        value={formData.geographicAreas}
                        onChange={handleChange}

                    />
                </div>
                <div >
                    <TextArea label={"How do you measure success in terms of conversions?"} row={2}
                        column={2}
                        name="conversionSuccess"
                        value={formData.conversionSuccess}
                        onChange={handleChange}
                        required
                    />
                    {errors.conversionSuccess && <p className="error">{errors.conversionSuccess}</p>}
                </div>
                <div >
                    <TextArea label={"What key performance indicators (KPIs) are important for you to track?"} row={2}
                        column={2} name="conversionSuccess"
                        value={formData.conversionSuccess}
                        onChange={handleChange}

                    />
                </div>
                <div >
                    <TextArea label={"What key performance indicators (KPIs) are important for you to track?"}
                        row={2} column={2}
                        name="kpis"
                        value={formData.kpis}
                        onChange={handleChange}


                    />
                </div>
                <div className="seo-buttons">
                    <button type='submit' className='submit-button' onClick={handleSubmit} disabled={loading}>Submit</button>
                    {/* <button type='button' className='cont-button'>Save And Continue Later</button> */}
                </div>

            </div>

        </div>
    )
}

export default SeoForm