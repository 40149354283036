import "src/scss/base/formDataModal.scss";
//Font based
import FontBased2 from 'src/Assests/logo-images/font-based/image 19.png'
import FontBased3 from 'src/Assests/logo-images/font-based/image 20.png'
import FontBased4 from 'src/Assests/logo-images/font-based/image 21.png'
import FontBased5 from 'src/Assests/logo-images/font-based/image 22.png'
import FontBased1 from 'src/Assests/logo-images/font-based/image18.png'

//Icon based
import IconBased1 from 'src/Assests/logo-images/icon-based/image 23.png'
import IconBased2 from 'src/Assests/logo-images/icon-based/image 24.png'
import IconBased3 from 'src/Assests/logo-images/icon-based/image 25.png'
import IconBased4 from 'src/Assests/logo-images/icon-based/image 26.png'
import IconBased5 from 'src/Assests/logo-images/icon-based/image 27.png'
import IconBased6 from 'src/Assests/logo-images/icon-based/image 28.png'
import IconBased7 from 'src/Assests/logo-images/icon-based/image 29.png'
import IconBased8 from 'src/Assests/logo-images/icon-based/image 30.png'
import IconBased9 from 'src/Assests/logo-images/icon-based/image 31.png'

//Color based
import ColorLogo1 from 'src/Assests/logo-images/color-logos/image 32.png'
import ColorLogo2 from 'src/Assests/logo-images/color-logos/image 33.png'
import ColorLogo3 from 'src/Assests/logo-images/color-logos/image 34.png'
import ColorLogo4 from 'src/Assests/logo-images/color-logos/image 35.png'
import ColorLogo5 from 'src/Assests/logo-images/color-logos/image 36.png'
import ColorLogo6 from 'src/Assests/logo-images/color-logos/image 37.png'
import ColorLogo7 from 'src/Assests/logo-images/color-logos/image 38.png'
import ColorLogo8 from 'src/Assests/logo-images/color-logos/image 39.png'
import ColorLogo9 from 'src/Assests/logo-images/color-logos/image 40.png'
import ColorLogo10 from 'src/Assests/logo-images/color-logos/image 59.png'
import ColorLogo11 from 'src/Assests/logo-images/color-logos/image 60.png'

//FontStyles
import FontStyle1 from 'src/Assests/logo-images/font-logos/image 41.png'
import FontStyle2 from 'src/Assests/logo-images/font-logos/image 42.png'
import FontStyle3 from 'src/Assests/logo-images/font-logos/image 43.png'
import FontStyle4 from 'src/Assests/logo-images/font-logos/image 44.png'
import FontStyle5 from 'src/Assests/logo-images/font-logos/image 45.png'
import FontStyle6 from 'src/Assests/logo-images/font-logos/image 46.png'
import FontStyle7 from 'src/Assests/logo-images/font-logos/image 47.png'
import FontStyle8 from 'src/Assests/logo-images/font-logos/image 48.png'
import FontStyle9 from 'src/Assests/logo-images/font-logos/image 49.png'
import FontStyle10 from 'src/Assests/logo-images/font-logos/image 61.png'
import FontStyle11 from 'src/Assests/logo-images/font-logos/image 62.png'
import FontStyle12 from 'src/Assests/logo-images/font-logos/image 63.png'
import FontStyle13 from 'src/Assests/logo-images/font-logos/image 64.png'
import FontStyle14 from 'src/Assests/logo-images/font-logos/image 65.png'
import FontStyle15 from 'src/Assests/logo-images/font-logos/image 66.png'

//Look logos
import LookLogos1 from 'src/Assests/logo-images/look-logos/image 50.png'
import LookLogos2 from 'src/Assests/logo-images/look-logos/image 51.png'
import LookLogos3 from 'src/Assests/logo-images/look-logos/image 52.png'
import LookLogos4 from 'src/Assests/logo-images/look-logos/image 53.png'
import LookLogos5 from 'src/Assests/logo-images/look-logos/image 54.png'
import LookLogos6 from 'src/Assests/logo-images/look-logos/image 55.png'
import LookLogos7 from 'src/Assests/logo-images/look-logos/image 56.png'
import LookLogos8 from 'src/Assests/logo-images/look-logos/image 57.png'
import LookLogos9 from 'src/Assests/logo-images/look-logos/image 58.png'
import LookLogos10 from 'src/Assests/logo-images/look-logos/image 67.png'
import LookLogos11 from 'src/Assests/logo-images/look-logos/image 68.png'
import LookLogos12 from 'src/Assests/logo-images/look-logos/image 69.png'
import { useRef } from "react";


const FormDataModal = ({ formData, onClose }) => {
    const data = [
        {
            title: "FONT BASED LOGOS",
            images: [
                { image: FontBased1, text: "Just Font" },
                { image: FontBased2, text: "Handmade" },
                { image: FontBased3, text: "Font + Meaning" },
                { image: FontBased4, text: "Initials" },
                { image: FontBased5, text: "Font in Shape" },
            ]
        },
        {
            title: "ICON BASED LOGOS",
            images: [
                { image: IconBased1, text: "Abstract Graphic" },
                { image: IconBased2, text: "Silhouette" },
                { image: IconBased3, text: "Geometric Symbol" },
                { image: IconBased4, text: "Illustrated Symbol" },
                { image: IconBased5, text: "Illustration" },
                { image: IconBased6, text: "Seals and Crests" },
                { image: IconBased7, text: "Mascot" },
                { image: IconBased8, text: "E-sports" },
                { image: IconBased9, text: "3D Logo" }
            ]
        },
        {
            title: "COLORS TO EXPLORE",
            images: [
                { image: ColorLogo1, text: "Blue" },
                { image: ColorLogo2, text: "Aqua" },
                { image: ColorLogo3, text: "Green" },
                { image: ColorLogo4, text: "Purple" },
                { image: ColorLogo5, text: "Pink" },
                { image: ColorLogo6, text: "Red" },
                { image: ColorLogo7, text: "Orange" },
                { image: ColorLogo8, text: "Yellow" },
                { image: ColorLogo9, text: "Light Neutrals" },
                { image: ColorLogo10, text: "Dark Neutrals" },
                { image: ColorLogo11, text: "Designer's Choice" }
            ]
        },
        {
            title: "FONT STYLE",
            images: [
                { image: FontStyle1, text: "Slim" },
                { image: FontStyle2, text: "Rounded" },
                { image: FontStyle3, text: "Bolder" },
                { image: FontStyle4, text: "Serif" },
                { image: FontStyle5, text: "San Serif" },
                { image: FontStyle6, text: "Calligraphy" },
                { image: FontStyle7, text: "Script" },
                { image: FontStyle8, text: "Techno" },
                { image: FontStyle9, text: "Retro" },
                { image: FontStyle10, text: "Comic" },
                { image: FontStyle11, text: "Handwritten" },
                { image: FontStyle12, text: "Graffiti" },
                { image: FontStyle13, text: "Typewriter" },
                { image: FontStyle14, text: "Stencil" },
                { image: FontStyle15, text: "Decorative" }
            ]
        },
        {
            title: "LOOK AND FEEL",
            images: [
                { image: LookLogos1, text: "Artistic" },
                { image: LookLogos2, text: "Minimal" },
                { image: LookLogos3, text: "Sophisticated" },
                { image: LookLogos4, text: "Corporate" },
                { image: LookLogos5, text: "Web 2.0" },
                { image: LookLogos6, text: "Retro" },
                { image: LookLogos7, text: "Fun" },
                { image: LookLogos8, text: "Hi-Tech" },
                { image: LookLogos9, text: "Feminine" },
                { image: LookLogos10, text: "Masculine" },
                { image: LookLogos11, text: "Sealed" },
                { image: LookLogos12, text: "Traditional" }
            ]
        },
    ];

    const modalRef = useRef(null);

    const handleBackdropClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            onClose();
        }
    };

    const formatKey = (key) => {
        return key
            .replace(/([A-Z])/g, " $1") // Add space before capital letters
            .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    };

    const renderValue = (key, value) => {
        if (typeof value === "boolean") {
            return value ? "Yes" : "No";
        }
        if (typeof value === "object" && value !== null) {
            if (Array.isArray(value)) {
                return value.join(", ");
            }
            return (
                <table>
                    <tbody>
                        {Object.entries(value).map(([subKey, subValue]) => {
                            const matchedData = data.find(
                                (item) => item.title === subKey
                            );
                            if (matchedData) {
                                return matchedData.images
                                    .filter((img) => subValue.includes(img.text))
                                    .map(({ image, text }) => (
                                        <tr key={text}>
                                            <td>{text}</td>
                                            <td>
                                                <img
                                                    src={image}
                                                    alt={text}
                                                    style={{
                                                        maxWidth: "50px",
                                                        maxHeight: "50px",
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ));
                            } else {
                                return (
                                    <tr key={subKey}>
                                        <td>{formatKey(subKey)}</td>
                                        <td>
                                            {Array.isArray(subValue)
                                                ? subValue.join(", ")
                                                : renderValue(subKey, subValue)}
                                        </td>
                                    </tr>
                                );
                            }
                        })}
                    </tbody>
                </table>
            );
        }
        return value;
    };
    return (
        <div className="form-data-modal" onClick={handleBackdropClick}>
            <div className="modal-content"
                ref={modalRef}
                onClick={(e) => e.stopPropagation()}

            >
                <h2>Form Data</h2>
                <button onClick={onClose} className="close-button">
                    Close
                </button>
                <table>
                    <thead>
                        <tr>
                            <th>Question</th>
                            <th>Response</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(formData).map(([key, value]) => (
                            <tr key={key}>
                                <td>{formatKey(key)}</td>
                                <td>{renderValue(key, value)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};


export default FormDataModal;
