import { useState } from "react";
import toast from "react-hot-toast";
import { generateAndSendPDF } from "src/utils/functions";
import Checkbox from "../base/checkbox";
import InputField from "../base/inputField";
import TextArea from "../base/textArea";


const initialData = {
    contactName: '',
    designation: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    industry: '',
    businessName: '',
    hasLogo: false,
    principalPurpose: '',
    targetAudience: '',
    existingWebsiteUrl: '',
    colorPreferences: '',
    competitors: '',
    paymentMethods: '',
    needCMS: false,
    needSLIntegration: false,
    platformPreference: '',
    specificRequirements: '',
    contentPages: '',
    accountLogin: false,
    contactInformation: false,
    bannerSlider: false,
    contentArea: false,
    newsEventsWidget: false,
    siteSearch: false,
    requestForInformationForm: false,
    blogFeed: false,
    featuredProducts: false,
    videoGallery: false,
    newsletterSubscription: false,
    shoppingCartWidget: false,
    socialMediaIcons: false,
    twitterFeeds: false,
    facebookFeeds: false,
    externalAPI: false,
    detailedFunctionalRequirements: '',
    adminFunctionalRequirements: '',
    completionDeadline: '',
    otherSuggestions: '',
    agreementEmail: '',
}
const WebsiteForm = () => {
    const [formData, setFormData] = useState(initialData);
    const [loading, setLoading] = useState(false)

    // State to store form errors
    const [formErrors, setFormErrors] = useState({});

    // Handler for input changes
    const handleInputChange = (e) => {
        if (!e || !e.target) {
            console.error('Event or event target is undefined');
            setLoading(false)
            return;
        }

        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };


    // Handler for form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        if (validateForm()) {
            const isSubmitted = await generateAndSendPDF({ name: formData.contactName, email: formData.email, formData }, "Website").then(res => { setFormData(initialData); });
        } else {
            toast.error("Please fill the required fields");
            setLoading(false)
        }
        setLoading(false)
    };
    // Form validation function
    const validateForm = () => {
        let errors = {};

        if (!formData.contactName) {
            errors.contactName = 'Contact Name is required';
        }
        if (!formData.designation) {
            errors.designation = 'Designation is required';
        }
        if (!formData.email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Email address is invalid';
        }
        if (!formData.phoneNumber) {
            errors.phoneNumber = 'Phone Number is required';
        }
        if (!formData.industry) {
            errors.industry = 'Industry is required';
        }
        if (!formData.businessName) {
            errors.businessName = 'Business Name is required';
        }
        if (!formData.agreementEmail) {
            errors.agreementEmail = 'Agreement Email is required';
        }
        if (!formData.detailedFunctionalRequirements) {
            errors.detailedFunctionalRequirements = 'Detailed functional requirements is required';
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };





    return (
        <div className="web-form" >
            <div className="web-form-info">
                <h1 className="heading">Website Design Questionnaire</h1>
                <p className="text">What's your vision for your website? Let us help you make it a reality</p>
            </div>

            <div className="web-detail1">
                <div>
                    <InputField
                        label="Contact Name"
                        name="contactName"
                        placeholder="Type Contact Name"
                        required
                        value={formData.contactName}
                        onChange={handleInputChange}
                    />
                    {formErrors.contactName && <p className="error">{formErrors.contactName} </p>}
                </div>
                <div>
                    <InputField
                        label="Designation"
                        name="designation"
                        placeholder="Type Designation"
                        required
                        value={formData.designation}
                        onChange={handleInputChange}
                    />
                    {formErrors.designation && <p className="error">{formErrors.designation}</p>}
                </div>
                <div>
                    <InputField
                        label="Company Name"
                        name="companyName"
                        placeholder="Type Company Name"
                        value={formData.companyName}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <InputField
                        label="Email"
                        name="email"
                        placeholder="Type Email"
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                    {formErrors.email && <p className="error">{formErrors.email}</p>}
                </div>
                <div>
                    <InputField
                        label="Phone Number"
                        name="phoneNumber"
                        type="number"
                        placeholder="Type Phone Number"
                        required
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                    />
                    {formErrors.phoneNumber && <p className="error">{formErrors.phoneNumber}</p>}
                </div>
                <div>
                    <InputField
                        label="Industry"
                        name="industry"
                        placeholder="Type Industry"
                        required
                        value={formData.industry}
                        onChange={handleInputChange}
                    />
                    {formErrors.industry && <p className="error">{formErrors.industry}</p>}
                </div>
            </div>

            <div className="web-form-info">
                <h1 className="heading">Business Specifics</h1>
            </div>

            <div className="web-detail1">
                <div>
                    <InputField
                        label="What is the Business Name?"
                        name="businessName"
                        placeholder="Your Business Name"
                        required
                        value={formData.businessName}
                        onChange={handleInputChange}
                    />
                    {formErrors.businessName && <p className="error">{formErrors.businessName}</p>}
                </div>
                <div>
                    <Checkbox
                        label="Do you have a Logo?"
                        name="hasLogo"
                        checked={formData.hasLogo}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className="web-form-info">
                <h1 className="heading">Business Specifics</h1>
            </div>

            <div className="web-detail1">
                <div>
                    <TextArea
                        label="What is the Principal Purpose of the website?"
                        name="principalPurpose"
                        value={formData.principalPurpose}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <TextArea
                        label="Who is the Target Audience?"
                        name="targetAudience"
                        value={formData.targetAudience}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <TextArea
                        label="Is there an existing website? If yes, please provide the URL"
                        name="existingWebsiteUrl"
                        value={formData.existingWebsiteUrl}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <TextArea
                        label="Are there any website color preferences?"
                        name="colorPreferences"
                        value={formData.colorPreferences}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <TextArea
                        label="Please mention 3 top competitors"
                        name="competitors"
                        value={formData.competitors}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <Checkbox
                        label="Do you need a CMS (Content Management System) to edit the content of the content pages like (About us, Privacy policy, FAQ etc.?)"
                        name="needCMS"
                        checked={formData.needCMS}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <Checkbox
                        label="SSL Integration required?"
                        name="needSLIntegration"
                        checked={formData.needSLIntegration}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <Checkbox
                        label="Do you have any preferences for the platform / technology to be used for the website?"
                        name="platformPreference"
                        noLabel="Open to best option which suits my business"
                        checked={formData.platformPreference}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <TextArea
                        label="Are there any specific requirements or preferences not mentioned above?"
                        name="specificRequirements"
                        value={formData.specificRequirements}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <TextArea
                        label="Please list down content pages you will have on your website? (Example: About Us, Privacy Policy, FAQs etc.)"
                        name="contentPages"
                        value={formData.contentPages}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className="web-form-info">
                <h1 className="heading">Please specify which of the below mentioned modules / components you would like to see on your home page?</h1>
            </div>

            <div className="web-detail1">
                <div>
                    <Checkbox
                        label="Account Login"
                        name="homepageModules.accountLogin"
                        required
                        checked={formData.accountLogin}
                        onChange={handleInputChange}
                    />
                    {formErrors['homepageModules.accountLogin'] && <p className="error">{formErrors['homepageModules.accountLogin']}</p>}
                </div>
                <div>
                    <Checkbox
                        label="Contact Information"
                        name="homepageModules.contactInformation"
                        required
                        checked={formData.contactInformation}
                        onChange={handleInputChange}
                    />
                    {formErrors['homepageModules.contactInformation'] && <p className="error">{formErrors['homepageModules.contactInformation']}</p>}
                </div>
                <div>
                    <Checkbox
                        label="Banner / Slider"
                        name="homepageModules.bannerSlider"
                        required
                        checked={formData.bannerSlider}
                        onChange={handleInputChange}
                    />
                    {formErrors['homepageModules.bannerSlider'] && <p className="error">{formErrors['homepageModules.bannerSlider']}</p>}
                </div>
                <div>
                    <Checkbox
                        label="Content Area"
                        name="homepageModules.contentArea"
                        required
                        checked={formData.contentArea}
                        onChange={handleInputChange}
                    />
                    {formErrors['homepageModules.contentArea'] && <p className="error">{formErrors['homepageModules.contentArea']}</p>}
                </div>
                <div>
                    <Checkbox
                        label="News and Events Widget"
                        name="homepageModules.newsEventsWidget"
                        required
                        checked={formData.newsEventsWidget}
                        onChange={handleInputChange}
                    />
                    {formErrors['homepageModules.newsEventsWidget'] && <p className="error">{formErrors['homepageModules.newsEventsWidget']}</p>}
                </div>
                <div>
                    <Checkbox
                        label="Site Search"
                        name="homepageModules.siteSearch"
                        required
                        checked={formData.siteSearch}
                        onChange={handleInputChange}
                    />
                    {formErrors['homepageModules.siteSearch'] && <p className="error">{formErrors['homepageModules.siteSearch']}</p>}
                </div>
                <div>
                    <Checkbox
                        label="Request For Information Form"
                        name="homepageModules.requestForInformationForm"
                        required
                        checked={formData.requestForInformationForm}
                        onChange={handleInputChange}
                    />
                    {formErrors['homepageModules.requestForInformationForm'] && <p className="error">{formErrors['homepageModules.requestForInformationForm']}</p>}
                </div>
                <div>
                    <Checkbox
                        label="Blog Feed"
                        name="homepageModules.blogFeed"
                        required
                        checked={formData.blogFeed}
                        onChange={handleInputChange}
                    />
                    {formErrors['homepageModules.blogFeed'] && <p className="error">{formErrors['homepageModules.blogFeed']}</p>}
                </div>
                <div>
                    <Checkbox
                        label="Featured Products"
                        name="homepageModules.featuredProducts"
                        required
                        checked={formData.featuredProducts}
                        onChange={handleInputChange}
                    />
                    {formErrors['homepageModules.featuredProducts'] && <p className="error">{formErrors['homepageModules.featuredProducts']}</p>}
                </div>
                <div>
                    <Checkbox
                        label="Video Gallery"
                        name="homepageModules.videoGallery"
                        required
                        checked={formData.videoGallery}
                        onChange={handleInputChange}
                    />
                    {formErrors['homepageModules.videoGallery'] && <p className="error">{formErrors['homepageModules.videoGallery']}</p>}

                </div>
                <div>
                    <Checkbox
                        label="Newsletter Subscription Option"
                        name="homepageModules.newsletterSubscription"
                        required
                        checked={formData.newsletterSubscription}
                        onChange={handleInputChange}
                    />
                    {formErrors['homepageModules.newsletterSubscription'] && <p className="error">{formErrors['homepageModules.newsletterSubscription']}</p>}

                </div>
                <div>
                    <Checkbox
                        label="Shopping Cart Widget"
                        name="homepageModules.shoppingCartWidget"
                        required
                        checked={formData.shoppingCartWidget}
                        onChange={handleInputChange}
                    />
                    {formErrors['homepageModules.shoppingCartWidget'] && <p className="error">{formErrors['homepageModules.shoppingCartWidget']}</p>}
                </div>
                <div>
                    <Checkbox
                        label="Social Media Icons"
                        name="homepageModules.socialMediaIcons"
                        required
                        checked={formData.socialMediaIcons}
                        onChange={handleInputChange}
                    />
                    {formErrors['homepageModules.socialMediaIcons'] && <p className="error">{formErrors['homepageModules.socialMediaIcons']}</p>}
                </div>
                <div>
                    <Checkbox
                        label="Twitter Feeds"
                        name="homepageModules.twitterFeeds"
                        required
                        checked={formData.twitterFeeds}
                        onChange={handleInputChange}
                    />
                    {formErrors['homepageModules.twitterFeeds'] && <p className="error">{formErrors['homepageModules.twitterFeeds']}</p>}
                </div>
                <div>
                    <Checkbox
                        label="Facebook Feeds"
                        name="homepageModules.facebookFeeds"
                        required
                        checked={formData.facebookFeeds}
                        onChange={handleInputChange}
                    />
                    {formErrors['homepageModules.facebookFeeds'] && <p className="error">{formErrors['homepageModules.facebookFeeds']}</p>}
                </div>
                <div>
                    <Checkbox
                        label="Any External API required?"
                        name="homepageModules.externalAPI"
                        required
                        checked={formData.externalAPI}
                        onChange={handleInputChange}
                    />
                    {formErrors['homepageModules.externalAPI'] && <p className="error">{formErrors['homepageModules.externalAPI']}</p>}
                </div>
                <div>
                    <TextArea
                        label="Detailed Functional Requirements: (Please mention the detailed functional requirements of the application)"
                        name="detailedFunctionalRequirements"
                        required
                        value={formData.detailedFunctionalRequirements}
                        onChange={handleInputChange}
                    />
                    {formErrors['detailedFunctionalRequirements'] && <p className="error">{formErrors['detailedFunctionalRequirements']}</p>}

                </div>
                <div>
                    <TextArea
                        label="Administration area Detail Functional Requirements (Please mention detailed functional requirements of Admin module)"
                        name="adminFunctionalRequirements"
                        value={formData.adminFunctionalRequirements}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <TextArea
                        label="Is there a specific deadline you have for the completion of the website? (Kindly note that website completion depends on the scope of work & is subject to extension if required)"
                        name="completionDeadline"
                        value={formData.completionDeadline}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <TextArea
                        label="Do you have any other suggestion, idea or requirements for the website?"
                        name="otherSuggestions"
                        value={formData.otherSuggestions}
                        onChange={handleInputChange}
                    />
                </div>
                <div style={{ padding: '20px' }}>
                    <p style={{ color: 'rgba(0, 90, 255, 1)', fontWeight: 700, fontSize: '15px' }}> Scope of Work Agreement:</p>
                    <p style={{ color: 'rgba(0, 90, 255, 1)', fontSize: '15px', marginTop: 10 }}>
                        This is to acknowledge that the both parties understand and agree to the scope of work mentioned above to the
                        best of their capabilities and any and all specifications not covered under the scope of work can/may be subject
                        to additional charges.
                    </p>
                </div>
                <div>
                    <InputField
                        label="Email"
                        name="agreementEmail"
                        value={formData.agreementEmail}
                        onChange={handleInputChange}
                    />
                    {formErrors.agreementEmail && <p className="error">{formErrors.agreementEmail}</p>}
                </div>
            </div>

            <div className="web-buttons">
                <button type="submit" className="submit-button" onClick={handleSubmit} disabled={loading}>
                    Submit
                </button>
                {/* <button type="button" className="cont-button">
                    Save And Continue Later
                </button> */}
            </div>
        </div>
    )
}

export default WebsiteForm