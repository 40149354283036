import { VscLoading } from "react-icons/vsc";
import { removeError } from 'src/utils/functions';




function ContactForm({ onSubmit, isLoading, setIsLoading, setError, setFormdata, error, formdata }) {

    const handleChange = (field, value) => {
        setFormdata(prev => ({
            ...prev, [field]: value
        }))

        setError(removeError(error, field))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)

        if (!formdata.name || !formdata.email || !formdata.phoneNo) {

            setError(prev => ({
                ...prev,
                name: !formdata.name ? "Name is Required" : "",
                email: !formdata.email ? "email is Required" : "",
                phoneNo: !formdata.phoneNo ? "Phone no is Required" : "",
            }))
            setIsLoading(false)

            return
        } else {

            onSubmit(formdata)

        }
    }

    return (
        <div class="contact">
            <div id="container">
                <form className='contact-form' onSubmit={handleSubmit}>
                    <div >
                        <div class="name">
                            <label for="name_input" style={{ color: 'white' }}>Full Name</label>
                            <input type="text" name="name" placeholder='Harry ' id="name_input" value={formdata.name} onChange={e => handleChange('name', e.target.value)} className={error?.name ? 'error' : ''} />
                        </div>
                        <div class="email">
                            <label for="email_input" style={{ color: 'white' }}>Email</label>
                            <input type="email" placeholder="pat@softlogic.dev" name="email" id="email_input" value={formdata.email} onChange={e => handleChange('email', e.target.value)} className={error?.email ? 'error' : ''} />
                        </div>
                    </div>
                    <div class="phoneNao">
                        <label for="phoneNo" style={{ color: 'white' }}>Phone Number</label>
                        <input type="number" placeholder="+11111 0000 1111 " name="phoneNo" id="phoneNo" value={formdata.phoneNo} onChange={e => handleChange('phoneNo', e.target.value)} className={error?.phoneNo ? 'error' : ''} />
                    </div>
                    <div class="message">
                        <label for="message_input" style={{ color: 'white' }}>Your Message</label>
                        <textarea name="message" placeholder="Type your message here" id="message_input" cols="20" rows="2" required value={formdata.message} onChange={e => handleChange('message', e.target.value)}></textarea>
                    </div>
                    <div class="submit">
                        <button className={`d-flex justify-content-center align-items-center ${isLoading ? 'loading' : ''}`} id='form_button' type='submit'>
                            {isLoading ? <VscLoading className="spin" /> : null}
                            SEND</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ContactForm;
