import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { useEffect } from 'react';
const LiveChat = () => {


    const onLoad = () => {
        console.log('onLoad works!');
    };

    useEffect(() => {
        const scriptId = 'tawk-script';
        // Ensure the script isn't already loaded
        if (!document.getElementById(scriptId)) {
            var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
            s1.id = scriptId;
            s1.async = true;
            s1.src = 'https://embed.tawk.to/662f2a9da0c6737bd13200e4/1hsk3sts9';
            s1.charset = 'UTF-8';
            s1.setAttribute('crossorigin', '*');
            s0.parentNode.insertBefore(s1, s0);
        }

        return () => {
            // Cleanup the script when component unmounts
            const el = document.getElementById(scriptId);
            if (el) {
                el.parentNode.removeChild(el);
            }
        };
    }, []);

    return (
        <div className="App">
            {/* <button onClick={handleMinimize}> Minimize the Chat </button> */}
            <TawkMessengerReact
                propertyId="662f2a9da0c6737bd13200e4"
                widgetId="1hsk3sts9"
                onLoad={onLoad}
            />
        </div>
    );
}

export default LiveChat


/*
<!--Start of Tawk.to Script-->
<script type="text/javascript">
var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function(){
var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
s1.async=true;
s1.src='https://embed.tawk.to/662f2a9da0c6737bd13200e4/1hsk3sts9';
s1.charset='UTF-8';
s1.setAttribute('crossorigin','*');
s0.parentNode.insertBefore(s1,s0);
})();
</script>
<!--End of Tawk.to Script-->
*/